import React from "react"

import Layout from "../../components/layout"
import Band from "../../components/band";

const faqContent = [
  {
    title: 'What happened?',
    content: <p>
      On January 28, 2021, Trusted Health Plans, Inc. (THP) learned that someone attacked our computer systems. They stole personal information. We immediately called in the expert computer security company, CrowdStrike, who assisted us in taking a series of steps designed to further protect personal information, including changing every password, monitoring for signs of data misuse, and finding out how the attack happened to avoid it from happening again.
    </p>,
  },
  {
    title: 'Who is responsible?',
    content: <p>
      We reported the incident to the Federal Bureau of Investigation and working with CrowdStrike, started our own inquiry. We believe that a foreign cybercriminal group was likely responsible.
    </p>,
  },
  {
    title: 'Why Does THP Have My Information?',
    content: <>
      <p>
        From February 2017 until September 2019, THP owned Trusted Health Plan Michigan, which offered a Michigan Medicaid plan.  As a result, personal information associated with Michigan Medicaid benefits is in THP’s computer systems.
      </p>
      <p>
        Beginning in 2017, THP owned Harbor Health Plan, which offered a Medicare Advantage and Exchange plans.  As a result, personal information associated with Harbor Health Plan Medicare Advantage and Exchange plans benefits is in THP’s computer systems.
      </p>
    </>,
  },
  {
    title: 'How do I know if my information was impacted?',
    content: <>
      <p>
        The information of anyone who has been an enrollee of Trusted Health Plan Michigan is impacted by the incident.  Former employees of Trusted Health Plan Michigan are impacted.
      </p>
      <p>
        The information for members who were enrolled in the Harbor Health Medicare Advantage or Exchange plans prior to December 2017 may have been impacted by the event.
      </p>
      <p>
        Providers who received payment from Trusted Health Plan Michigan for services provided to Michigan Medicaid enrollees may be affected by this incident.  Additionally, providers who received payment from Harbor Health Plan for services provided to Harbor Health Medicare Advantage or Exchange plans enrollees may also be affected by this incident.
      </p>
    </>,
  },
  {
    title: 'For enrollees of Harbor Health, what type of personal information may have been accessed or taken?',
    content: <p>
      The attackers may have gained access or taken personal information such as your full name, address, telephone number, date of birth, Social Security number (Medicare Advantage enrollees only), health insurance identification number and medical information. This included claims information.
    </p>,
  },
  {
    title: 'For enrollees of Trusted Health Plan Michigan what type of personal information may have been accessed or taken?',
    content: <p>
      The attackers may have gained access or taken personal information such as your full name, address, telephone number, date of birth, Medicaid identification number and medical information. This included claims information and in limited instances clinical information.
    </p>,
  },
  {
    title: 'For providers who received payment from Trusted Health Plan Michigan or Harbor Health what type of informaton may have been accessed or taken?',
    content: <p>
      The attackers may have gained access to the following personal and/or business information about you: full name, business address and Social Security number or tax identification number, whichever number you use for tax purposes.
    </p>,
  },
  {
    title: 'For former employees of Trusted Health Plan Michigan what kinds what kinds of personal information may have been accessed or taken?',
    content: <p>
      The attackers may have gained access or taken current and former employees the following personal information: full name, address, date of birth and Social Security number.
    </p>,
  },
  {
    title: 'For parties conducting business with Trusted Health Plan Michigan or Harbor Health, what information may have been accessed or taken?',
    content: <p>
      The attackers may have gained access to or taken your business name, business address and social security number or tax identification number, whichever number you use for tax purposes.
    </p>,
  },
  {
    title: 'Are you offering credit monitoring or identity protection services? ',
    content: <>
      <p>
        Yes, we are offering two years of free credit monitoring and identity theft protection services to those individuals affected through Experian’s<sup>&reg;</sup> IdentityWorks&#8480;.  Affected individuals will receive a letter from us. That letter will contain a personalized code that will be used to access the free protection services. If you have received a letter, you can click on the link at Free Credit Monitoring tab above to enroll or call Experian at (855) 347-6549.
      </p>
      <p>
        Again, you must have the personalized code contained in your notification to enroll.  You will not be able to enroll at the number above until you have received a letter with your personalized code.
      </p>
    </>,
  },
  {
    title: 'Do the protections being offered extend to children who are minors?',
    content: <p>
      Yes - dependents under 18 who are affected will receive a letter in care of the parent or guardian notifying them and detailing how to enroll in Experian’s<sup>&reg;</sup> IdentityWorks&#8480; service.  If you have received a letter, you can click on the link at Free Credit Monitoring tab above to enroll or call Experian at (855) 347-6549.
    </p>,
  },
  {
    title: 'Are the services free of charge and do I need a credit card to enroll?',
    content: <p>
      These services are free of charge and do not require a credit card or other financial information to enroll.
    </p>,
  },
  {
    title: 'When can I enroll in the protections being offered?',
    content: <p>
      You can enroll once you receive a letter with your personalized code.
    </p>,
  },
  {
    title: 'I received my notification letter, how do I enroll? ',
    content: <p>
      If you have received a letter, you can click on the link at Free Credit Monitoring tab above to enroll or call Experian at (855) 347-6549.
    </p>,
  },
  {
    title: 'What should I do if I never received a letter and believe I am affected?',
    content: <p>
      We have established a process to provide you with access to credit monitoring and identity
      theft protection if you were impacted by the cyberattack but did not receive a letter.
      Click on the tab labeled "Didn't Get a Letter?"
      There, you will see a substitute notice letter for individuals who did not receive a letter in the
      mail. Follow the instructions in the notice to receive via email the personalized code necessary
      to enroll in the protections offered. You may also contact Experian directly at (855) 347-6549
      and tell them you believe you were affected by the cyberattack and did not receive a letter.
    </p>,
  },
  {
    title: 'Are you calling or sending emails about this incident?',
    content: <>
      <p>
        No - We are committed to protecting your privacy. We will not contact you by email, text or phone about this event. If you receive inquiries by phone, text, email or social media that say they are about this event, they are <strong>not</strong> from us. Do not click on any links or attachments in email messages or provide any personal information in response.
      </p>
      <p>
        If you have questions, please contact Experian at (855) 347-6549. You can also reach us by email at chpdcanswers@carefirst.com or by mail at THPPrivacy Office, P.O. Box 14858, Lexington, KY 40512. You may also contact us directly at 202-821-1100.
      </p>
    </>,
  },
  {
    title: 'I have received a ransom email, text or phone call threatening to disclose personal or other sensitive information if I don’t pay a ransom. What do I do?',
    content: <>
      <p>
        If you receive a ransom email or text, never click on any links, download or open any files included in the message. These emails or texts may be used by attackers to attempt to harm your computer or device or further a cyberattack against you.
      </p>
      <p>
        You should also never respond to ransom emails, texts of phone calls or attempt to engage with the senders of a ransom note under any circumstances. You should delete any texts or emails from your computer or device, including your trash folder.
      </p>
    </>,
  },
]

const FaqAccordion = () => faqContent
  .map(({ title, content }) => ({
    title,
    Content: () => content // convert JSX to component
  }))
  .map(({ title, Content }, index) => (
    <div key={index} className="card">
      <div className="card-header" id="headingOne">
        <h5 className="mb-0">
          <button
            className="btn btn-link accordion-btn"
            data-toggle="collapse"
            data-target={`#collapse${index}`}
            aria-expanded="false"
            aria-controls={`collapse${index}`}>
            {title}
          </button>
        </h5>
      </div>
      <div id={`collapse${index}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
        <div className="card-body">
          <Content />
        </div>
      </div>
    </div>
  ));

const Faq = () => (
  <Layout header="Michigan Plans FAQ" isMi={true}>
    <Band>
      <div id="accordion" className="accordion">
        <FaqAccordion />
      </div>
    </Band>
  </Layout>
)

export default Faq
